@import '~typeface-ubuntu/index.css';

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px;
}

.opcion-header{
  cursor: pointer;
}

.opcion-header-subrayado{
  cursor: pointer;
  text-decoration: underline red 3px; /* Añade un subrayado al pasar el mouse sobre el enlace */
}

/* Mobile styles */

@media(max-width: 768px){
  .header {
    margin-bottom: 5px;
  }

  .barras {
    background-color: transparent;
    display: block;
  }

  .toggle-btn {
    display: block;
    border: none;
    background-color: transparent;
  }

  .toggle-btn img, .cerrar-btn img{
    height: 30px;
  }

  .cerrar-btn{
    display: block;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 20px;
    top: 35px;
  }

  nav {
    position: fixed;
    top: 0;
    right: -250px; /* Cambia esto a un valor negativo para ocultar el menú lateral inicialmente */
    height: 100%;
    width: 250px;
    background-color: white;
    padding-top: 60px;
    transition: 0.5s;
    z-index: 10;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  nav li {
    padding: 10px;
    color: white;
  }
  
  nav.active {
    right: 0;
  }

  nav ul li a {
    color: #013545;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    text-decoration: none;
    font-size: 15px;
    margin-left: 5px;
  }

  li {
    display: block;
    margin-bottom: 5px;
  }

  .logo img {
    width: 160px; /* Ajusta el tamaño de la imagen según sea necesario */
    height: auto;
    margin-left: 0px;
  }
}



/* -------------------------------------------------------- */

/* Desktop styles */
@media(min-width: 769px){

header {
  margin-bottom: 100px;
}

nav ul {
  list-style-type: none;
  display: flex;
}

nav ul li {
  margin-right: 20px;
  display: flex;
  align-items: flex-start;
  text-align: center;
}

nav ul li:last-child {
  margin-right: 0; /* Elimina el margen derecho del último elemento para evitar espacio extra */
}

nav ul li a {
    color: #013545;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    text-decoration: none;
    font-size: 15px;
  }

nav ul li a:hover {
    text-decoration: underline red 3px; /* Añade un subrayado al pasar el mouse sobre el enlace */
  }

.logo img {
    width: 16vw; /* Ajusta el tamaño de la imagen según sea necesario */
    height: auto;
    margin-left: 12vw;
  }

.logo{
    cursor: pointer;
}

.toggle-btn, .cerrar-btn{
  display: none;
}
}


  
