/* Desktop styles */
@media(min-width: 769px){
  .contenedor-paso-2{
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    height: 90vh;
  }

  .contenedor-cotizador-titulo-planes{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }

  .contenedor-imagen-puntos{
    display: flex;
    justify-content: flex-start;
    margin-left: 1%;
    margin-top: 8%;
    height: 100%;
    width: 20%;
  }

  .icono-puntos {
    width: 16vw; 
    height: 6vw;
  }

  .contenedor-texto-planes-1{
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    width: 100%;
  }

  .contenedor-texto-planes-2{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
  }
}

/* ------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .contenedor-paso-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .contenedor-cotizador-titulo-planes{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contenedor-imagen-puntos{
    display: flex;
    height: 100%;
    width: 20%;
    
  }

  .icono-puntos {
    width: 15vw; 
    height: 5vw;
    margin-right: 10px; 
    margin-left: 25px;
  }
}