  .contenedor-principal-requisitos{
    display: flex;
    flex-direction: column;
    font-family: 'Ubuntu', sans-serif;
  }  

  .contenedor-texto-requisitos{
    margin: 1%;
  }

  .contenedor-texto-requisitos-negocio{
    margin: 1%;
    height: 40vh;
  }
  
  .titulo-requisitos-seguro {
    font-size: 30px;
    font-weight: 800;
    font-family: 'Ubuntu', 'sans-serif';
    color: #013545;
    text-align: left;
  }

  .subtitulo-requisitos-seguro {
    font-size: 17px;
    font-weight: 700;
    margin-top: 15px;
  }

  .contenedor-encabezado-requisitos{
    margin-bottom: -3%;
  }

    /* ---------------------------------------------- */

  /* Mobile styles */
  @media(max-width: 768px){
  .contenedor-encabezado-requisitos{
    margin-bottom: 3%;
  }
  }