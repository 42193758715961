/* 
  Desktop styles
*/
@media(min-width: 769px){

  .contenedor-principal{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    box-sizing: border-box;
    width: auto;
  }

  .contenedor-onda-gif{
    display: flex;
    position: relative;
    justify-content:flex-start;
    align-items: flex-start;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .onda-gif-AP{
    position: relative;
    z-index: 1;
    left: 0%;
    bottom: -30vh;
  }

  .contenedor-asesoria{
    display: flex;
    align-content: center;
    justify-content: flex-start;
    margin-top: 2%;
    background-color: rgb(228, 224, 224);
    padding-left: 0;
    height: 60vh;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }

  .contenedor-gif-titulo-foto{
    justify-items: flex-start;
    position: relative;
    width: 95vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    left: -29%; 
  }

  .contenedor-texto{
    border: none;
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-top: 9%;
  }

  .contenedor-foto-marcos{
    display: flex;
    position: relative;
    justify-content:flex-end;
    align-items: flex-end;
    width: 20%;
    margin: 0;
    /*right: -10%;*/
    margin-right: 3vw;
    bottom: 0;
  }

  .foto-marcos{
    position: relative;
    z-index: 1;
    object-fit: cover;
    width: 70vw;
    height: 70vh;
  }

  .imagen-wsp{
    position: absolute;
    z-index: 2;
    bottom: 10%;
    right: -25%;
    height: 14%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }


  .texto-asesoria{
    font-size: 3vw;
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    color: #013545;
  }

  .texto-a-tu-alcance{
    font-size: 4vw;
    font-weight: bold;
    margin: 0;
    color: #013545;
    letter-spacing: 1px;
    font-family: 'Ubuntu', sans-serif;
  }

  .subrayado-asesoria{
    border-top: 7px solid red;
    width: 50%;
  }

  .footer{
    z-index: 1;
  }

  .gif-container-AP{
    position: relative;
    z-index: 3;
    object-fit: cover;
    bottom: -2%;
    left: 0;
    /*width: auto; */
    margin-left: 10vw;
  }

  .boton-wsp-AP {
    display: inline-block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
}

/* ---------------------------------------- */

/* Mobile styles */
@media(max-width: 768px){

  .contenedor-principal{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .contenedor-asesoria{
    display: flex;
    align-content: center;
    justify-content: flex-start;
    margin: 0;
    background-color: rgb(228, 224, 224);
    height: 28vh;
    max-height: 250px;
    position: relative;
  }

  .contenedor-texto{
    margin-top: 20%;
    margin-left: 0%;
  }

  .imagen-wsp{
    position: absolute;
    z-index: 2;
    bottom: 5%;
    left: 45vw;
    height: 12%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .contenedor-imagen-wsp{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .texto-asesoria{
    font-size: 5.2vw;
    font-weight: 420;
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    color: #013545;
  }

  .texto-a-tu-alcance{
    font-size: 6.8vw;
    font-weight: bold;
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    color: #013545;
    letter-spacing: 1px;
    width: 100%;
  }

  .subrayado-asesoria{
    border-top: 5.2px solid red;
    width: 55%;
  }

  .gif-container-AP{
    position: relative;
    z-index: 3;
    object-fit: cover;
    top: -2vh;
    left: -2vh;
  }

  .contenedor-gif-titulo-foto{
    justify-items: center;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: -35%; 
  }

  .contenedor-onda-gif{
    display: flex;
    position: relative;
    justify-content:flex-start;
    align-items: flex-start;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .onda-gif-AP{
    position: relative;
    z-index: 1;
    margin-top: 5vw;
    left: 0;
    top: 40%;
  }

  .boton-wsp-AP {
    display: inline-block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
}