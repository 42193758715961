@import '~typeface-ubuntu/index.css';

/* Desktop styles */
@media(min-width: 769px) {
 .contenedor-titulo{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-family: 'Ubuntu', sans-serif;
  } 

  .titulo{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
    font-weight: bold;
    font-size: 2.5vw;


  }

  .titulo-asesoria{
    color: #013545;
    margin-bottom: 40px;
    margin-top: 100px;
    font-size: 3.5vw;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
  }

  .subtitulo{
    color: white;
    font-size: 1.6vw;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .subrayado-inicio{
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    border-top: 0.3vw solid red;
    width: 29vw;
  }

  .subrayado-asesoria-plus{
    margin-left: auto;
    margin-right: auto;
    border-top: 6.4px solid red;
    width: 85%;
  }
}

/* ----------------------------------------------------- */

/* Mobile styles */
@media(max-width: 768px) {
  .contenedor-titulo{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-family: 'Ubuntu', sans-serif;
  } 

  .titulo{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    margin-bottom: 10px;
    margin-top: 40px;
    font-size: 7vw;
    font-weight: 600;
    white-space: nowrap;
  }

  .titulo-asesoria{
    color: #013545;
    margin-bottom: 10px;
    margin-top: 40px;
    font-size: 7vw;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
  }

  .subtitulo{
    color: white;
    font-size: 3.8vw;
    font-weight: 400;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .subrayado-inicio{
    margin-left: auto;
    margin-right: auto;
    border-top: 4px solid red;
    width: 82%;
  }

  .subrayado-asesoria-plus{
    margin-left: auto;
    margin-right: auto;
    border-top: 3px solid red;
    width: 90%;
  }
}



