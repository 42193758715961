.contenedor-principal-nosotros{
  display: flex;
  flex-direction: column;
}  

  
.contenedor-nosotros {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0;
  margin-top: 0;
  background-color: rgb(228, 224, 224);
  position: relative;
  text-align: center;
  flex-direction: column;
}
  
.texto-nosotros{
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  color: #013545;
  text-align: center;
}

.contenedor-circulos {
  display: flex;
  justify-content: center; 
  align-items: center;
}

.circulo {
  border-radius: 50%;
  background-color: #013545;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Sombreado alrededor del círculo */
}

.texto-circulo {
  font-weight: normal;
  color: white;
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
}

.circulo::before, .circulo::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(to left, #E51C30, #422A70); 
}

.contenedor-empresas{
  display: flex;
  justify-content: center;
  align-items: center;
}

  /* Mobile styles */
@media(max-width: 768px){

.contenedor-nosotros {
    height: 95vh;
    max-height: 700px;
}  

.texto-nosotros{
  margin-top: 50px;
  font-size: 3.5vh;
  text-decoration: underline red 3px; 
  text-underline-offset: 5px;
}

.contenedor-circulos {
  flex-direction: column;
  margin-top: 0px;
}

.circulo {
  width: 26vh;
  height: 26vh;
}

.circulo1 {
  top: 4.5vw;
}

.circulo2 {
  top: 2.5vw;
  z-index: 4;
}

.circulo3 {
  top: 0;
}

.texto-circulo {
  margin: 1vh; 
  font-size: 2vh;
}

.circulo1::before {
  top: 75%; /* Ajusta según sea necesario para la posición vertical */
  left: 24%;
  width: 5vh;
  height: 5vh;
  transform: translateX(-100%);
}

.circulo1::after {
  bottom: 8%;
  right: -20%;
  transform: translateX(-50%);
  z-index: 0;
  height: 7.5vh;
  width: 7.5vh;
}

.circulo2::before {
  top: 78%; /* Ajusta según sea necesario para la posición vertical */
  left: 40%;
  width: 8.5vh;
  height: 8.5vh;
  transform: translateX(-100%);
}

.circulo2::after {
  bottom: 15%; /* Ajusta según sea necesario para la posición vertical */
  right: -18%;
  height: 4.5vh;
  width: 4.5vh;
  transform: translateX(-100%);
}

.circulo3::after {
  bottom: 0;
  right: -30%;
  z-index: 0;
  height: 8vh;
  width: 8vh;
  transform: translateX(-100%);
}

.contenedor-empresas{
  flex-direction: column;
  height: 40vh;
}

.imagenes-empresas-1,
 .imagenes-empresas-2,
 .imagenes-empresas-3{
  width: auto;
  margin-top: 30px;
  margin-right: 10px;
}

.imagenes-empresas-1{
  height: 6vh;
}

.imagenes-empresas-2{
  height: 6.5vh;
}

.imagenes-empresas-3{
  height: 7vh;
}


}


    /* Desktop styles */
@media(min-width: 769px){

.contenedor-nosotros {
    height: 33vw;
    max-height: 700px;
}

.texto-nosotros{
  font-size: 3vw;
  text-decoration: underline red 4px; 
  text-underline-offset: 10px;
}

.contenedor-circulos {
    flex-direction: row;
    margin-top: 20px;
}

.circulo {
    width: 22vw;
    height: 22vw;
 }

.circulo1 {
    top: 0;
    right: -1vw;
  }
  
.circulo2 {
    top: 2.5vw;
    z-index: 4;
  }
  
.circulo3 {
    top: 0;
    left: -1vw;
  }

.texto-circulo {
    margin: 2vw; 
    font-size: 1.79vw;
}

.circulo1::before {
  top: 77%; /* Ajusta según sea necesario para la posición vertical */
  left: 23%;
  width: 4vw;
  height: 4vw;
  transform: translateX(-100%);
}

.circulo1::after {
    bottom: 10%;
    right: -20%;
    transform: translateX(-50%);
    z-index: 0;
    height: 6vw;
    width: 6vw;
  }

.circulo2::before {
    top: -10%; /* Ajusta según sea necesario para la posición vertical */
    left: 47%;
    width: 8vw;
    height: 8vw;
    transform: translateX(-100%);
  }

.circulo2::after {
    bottom: 8%; /* Ajusta según sea necesario para la posición vertical */
    right: -14%;
    height: 4vw;
    width: 4vw;
    transform: translateX(-100%);
  }


.circulo3::after {
    bottom: 8%;
    right: -33%;
    z-index: 0;
    height: 6vw;
    width: 6vw;
    transform: translateX(-100%);
  }

.contenedor-empresas{
    flex-direction: row;
    height: 20vw;
}

.imagenes-empresas-1,
 .imagenes-empresas-2,
 .imagenes-empresas-3{
  width: auto;
  margin-top: 30px;
  margin-right: 10px;
}

.imagenes-empresas-1{
  height: 4vw;
}

.imagenes-empresas-2{
  height: 4.5vw;
}

.imagenes-empresas-3{
  height: 5vw;
}

}