/* Mobile styles */
@media(max-width: 768px){
  .lista-tipo-plan-1,
  .lista-tipo-plan-2{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 25px;
    }

  .contenedor-instrumentos-musicales{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
  }


  .contenedor-boton-flecha{
    display: flex;
    justify-content: end;
    align-content: center;
  }

  .contenendor-inputs-todo-riesgo-elec p{
    font-family: 'Ubuntu', sans-serif;
    text-indent: 6%;
    font-size: 14px;
    color: gray;
    font-style: italic;
  }


}

/* -------------------------------------------------------- */

/* Desktop styles */
@media(min-width: 769px){
  .lista-tipo-plan-1{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10px;
  }

  .lista-tipo-plan-2{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: 10px;
  }

  .tipo-plan-item-2{
    margin-right: 80px;
  }

  .contenedor-lista-tipo-plan-2{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    margin-left: 10px;
  }

  .lista-tipo-plan-instrumentos{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 80px;
  }

  .contenendor-inputs-todo-riesgo-elec{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 300px;
  }

  .contenedor-boton-flecha{
    display: flex;
    justify-content: flex-end;
    width: 40vw;
    margin-bottom: 100px;
  }

  .contenendor-inputs-todo-riesgo-elec p{
    margin-top: -1%;
    font-size: 0.95vw;
    margin-bottom: -2%;
    text-indent: 2.5%;
    color: gray;
    font-style: italic;
  }
}

 