@import '~typeface-ubuntu/index.css';

.btn-cotizar{
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  border: none;
  color: white;
  background: linear-gradient(to left, #E51C30, #422A70); 
  cursor: pointer;
  align-items: center;
}

.btn-cotizar-encabezado{
  font-family: 'Ubuntu', sans-serif;
  width:100px;
  height: 25px;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 50px;
  color: white;
  background: linear-gradient(to left, #E51C30, #422A70); 
  cursor: pointer;
  align-items: center;
}

.btn-cotizar-AP{
  font-family: 'Ubuntu', sans-serif;
  padding: 5px 10px;
  font-weight: 600;
  border: none;
  color: white;
  background: linear-gradient(to left, #E51C30, #422A70); 
  cursor: pointer;
  align-items: center;
  margin-top: 1vw;
}

.centrar{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Mobile styles */
@media(max-width: 768px){
  .btn-cotizar{
    width: 120px;
    height: 32px;
    align-content: center;
    font-size: 15px;
    border-radius: 9px;
    margin-left: -5%;
  }

  .btn-cotizar-AP{
    width: 40vw;
    height: 12vw;
    font-size: 20px;
    border-radius: 10px;
  }

  .centrar-AP{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* Desktop styles  */
@media(min-width: 769px) {
.btn-cotizar{
    padding: 10px 20px;
    width:270px;
    height: 75px;
    font-size: 35px;
    border-radius: 15px;
  }

.btn-cotizar-AP{
  width:125px;
  height: 32px;
  font-size: 15px;
  border-radius: 50px;
  letter-spacing: 0.5px;
}

}
