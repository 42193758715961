   .contenedor-principal-seccion-negocio{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-family: 'Ubuntu', 'sans-serif';
    width: 100%;
    object-fit: cover;
  }   

  .contenedor-texto-seccion-negocio{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    font-family: 'Ubuntu', 'sans-serif';
    text-align: center;
  }  


  /* Desktop styles */
@media(min-width: 768px){ 
  .contenedor-fondo-seccion-negocio{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    background-image: url('../media/banner1\ \(2\).png');
    background-size: cover;
    height: 25.5vw;
    width: 101%;
    margin-left: -1%;
 }

  .imagen-negocio-seccion{
    height: 18.5vw;
  }

  .imagen-casa-negocio-seccion{
    margin-top: 7%;
    margin-left: -20%;
  }

  .contenedor-texto-seccion-negocio{
    margin-left: -10%;
  }

  .titulo-seccion-negocio{
    font-size: 3.5vw;
    font-weight: 400;
    margin-top: 5%;
    color: white;
    line-height: 0.3;
  }

  .primer-p p {
    margin-bottom: 0; 
}

  .btn-negocio-seccion {
    width: 22vw;
    height: 12vh;
    /* min-width: 45vh; */
    padding: 10px 20px;
    font-size: 2.5vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #E51C30, #422A70);
    cursor: pointer;
    letter-spacing: 1px;
    margin: 0 auto; 
  }
}

  /* ---------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .contenedor-fondo-seccion-negocio{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    background-image: url('../media/banner1\ \(2\).png');
    background-size: cover;
    height: 22vh;
    width: 101%;
    margin-left: -1%;
 }

   .imagen-negocio-seccion{
    height: 14vh;
  }

  .imagen-casa-negocio-seccion{
    margin-top: 17%;
    margin-left: 4%;
  }

  .contenedor-texto-seccion-negocio{
    width: 100%;
    margin-left: -25%;
    line-height: 0.2;
  }

  .titulo-seccion-negocio{
    font-size: 2vh;
    font-weight: 400;
    margin-top: 10px;
    color: white;
    width: 100%;
  }

  .btn-negocio-seccion {
    width: fit-content;
    height: 4.5vh;
    /* min-width: 45vh; */
    padding: 10px 20px;
    font-size: 1.8vh;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #E51C30, #422A70);
    cursor: pointer;
    margin-left: 50px; 
  }
}
