/* Estilos para el botón de redes sociales */
.btn-red-social {
    text-decoration: none; /* Elimina el subrayado del enlace */
    display: inline-block; /* Alinea los botones en línea */
  }
  
.btn-red-social:hover img {
    /* Estilos al pasar el ratón sobre el botón (opcional) */
    filter: brightness(80%); /* Reduce el brillo al 80% */
  }


 /* Mobile styles */

@media(max-width: 768px){
    .btn-red-social img {
        width: 25px; /* Ajusta el ancho de la imagen según sea necesario */
        height: auto; /* Ajusta la altura de la imagen según sea necesario */
        border-radius: 30; /* Aplica un borde redondeado a la imagen (opcional) */
      }

    .btn-red-social {
      margin-right: 10px; /* Espacio entre los botones */
    }
}


    /* Desktop styles */
@media(min-width: 769px){
    .btn-red-social img {
        width: 3vw; /* Ajusta el ancho de la imagen según sea necesario */
        height: auto; /* Ajusta la altura de la imagen según sea necesario */
        border-radius: 30; /* Aplica un borde redondeado a la imagen (opcional) */
      }

    .btn-red-social {
      margin-right: 20px; /* Espacio entre los botones */
    }
}

  