  .contenedor-principal-seccion-asociaciones{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-family: 'Ubuntu', 'sans-serif';
    width: 100%;
    object-fit: cover;
  }  

  /* Desktop styles */
  @media(min-width: 768px){ 

  .contenedor-fondo-seccion-asociaciones{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right;
    box-sizing: border-box;
    background-image: url('../media/fondoprofesionales.svg');
    /* hay que reemplazar por imagen */
    background-size: 120%;
    background-repeat: no-repeat;
    height: 60vh;
    width: 106%;
    margin-left: -6%;
 }

 .contenedor-texto-seccion-asociaciones{
    display: flex;
    flex-direction: column;
    align-content: right;
    font-family: 'Ubuntu', 'sans-serif';
    text-align: right;
    margin-right: 2%;
  }  


  .titulo-seccion-asociaciones{
    font-size: 3vw;
    font-weight: 400;
    color: #013545;
    line-height: 0.1;
    margin-top: 5%;
  }

  .btn-asociaciones-seccion {
    width: 25vw;
    height: 10vh;
    /* min-width: 45vh; */
    padding: 10px 20px;
    font-size: 2.3vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #E51C30, #422A70);
    cursor: pointer;
    letter-spacing: 1px;
    margin-left: auto; 
  }
}

  /* ---------------------------------- */

/* Mobile styles */
@media(max-width: 768px){
  .contenedor-fondo-seccion-asociaciones{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right;
    box-sizing: border-box;
    background-image: url('../media/fondoprofesionalesmobile.svg');
    background-size: 110%;
    height: 22vh;
    width: 106.5%;
    margin-left: -7%;
 }

   .contenedor-texto-seccion-asociaciones{
    display: flex;
    flex-direction: column;
    align-content: right;
    font-family: 'Ubuntu', 'sans-serif';
    text-align: right;
    margin-top: 3%;
    margin-right: 3%;
  }  

  .titulo-seccion-asociaciones{
    font-size: 2.2vh;
    font-weight: 400;
    color: #013545;
    line-height: 0.2;
  }

  .btn-asociaciones-seccion {
    width: fit-content;
    height: 4.5vh;
    /* min-width: 45vh; */
    padding: 10px 20px;
    font-size: 1.8vh;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    color: white;
    background: linear-gradient(to right, #E51C30, #422A70);
    cursor: pointer;
    margin-left: auto; 
  }

}