/* Mobile styles */
@media(max-width: 768px){
  .contenedor-cotizacion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20rem;
  }

  .titulo-cotizacion{

  }


}