@import '~typeface-ubuntu/index.css';

/* Mobile styles */
@media(max-width: 768px){
  .cotizador-inicio{
    background-color: #013545;
    border-style: none;
    margin-bottom: 100px;
    font-family: 'Ubuntu', sans-serif;
  }

  .cotizador-asesoria-plus{
    border-style: none;
    background-color: #72B8BD;
    margin-bottom: 100px;
  }

  .contenedor-icono-y-texto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contenedor-nombre-tipo-seguro{
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .nombre-tipo-seguro{
    color: white;
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 50%;
  }

  .icono-tipo-seguro-inicio{
    height: 18vw;
    width:  18vw;
  }

  .icono-tipo-seguro-asesoria-plus{
    height: 18vw;
    width:  18vw;
  }

}

/* -------------------------------------------------------- */

/* Desktop styles */
@media(min-width: 769px){
  .cotizador-inicio{
    background-color: transparent;
    border-style: none;
    margin-bottom: 100px;
    font-family: 'Ubuntu', sans-serif;
    width: 12.5vw;
  }

  .cotizador-asesoria-plus{
    border-style: none;
    background-color: #72B8BD;
    margin-bottom: 100px;
  }

  .contenedor-icono-y-texto{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12.5vw;
    margin-right: 5%;
  }

  .contenedor-nombre-tipo-seguro,
  .contendor-nombre-tipo-seguro-hovered{
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .nombre-tipo-seguro{
    color: white;
    font-size: 0.8vw;
    display: flex;
    flex-wrap: wrap;
    width: 46.5%;
    margin-top: 0px;
  }

  
  .nombre-tipo-seguro-hovered,
  .selected .nombre-tipo-seguro{
    color: black;
    font-size: 0.8vw;
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin-top: 0px;
  } 

  .icono-tipo-seguro-inicio,
  .icono-tipo-seguro-asesoria-plus{
    position: relative;
    z-index: 3;
    height: 10vw;
    width:  10vw;
    cursor: pointer;
  }
 
  .contenedor-hover-icono{
    display: flex;
    justify-content: center;
  }

  .hover-icono-seguro-hovered{
    background-color: rgb(255, 255, 255);
    position: absolute;
    z-index: 1;
    height: 10vw;
    width:  10vw;
    top: 50.5%;
    border-radius: 50%;
  }

  .hover-icono-seguro-hovered-AP{
    background-color: rgb(255, 255, 255);
    position: absolute;
    z-index: 1;
    height: 10vw;
    width:  10vw;
    top: 300px;
    border-radius: 50%;
  }
  
  .selected {
    background-color: rgb(255, 255, 255);
    position: absolute;
    z-index: 1;
    height: 10vw;
    width:  10vw;
    top: 15%;
    border-radius: 50%;
  }

  .selected .hover-icono-seguro-hovered-AP {
    background-color: transparent;
  }

  .not-selected {
    background-color: transparent;
  }

}