@import '~typeface-ubuntu/index.css';

/* mobile styles */
@media(max-width: 768px){
  .dropdown-select {
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 200;
    width: 300px;
    height: 50px;
    padding: 12px 20px;
    margin-top: 15px;
    box-sizing: border-box;
    border: 1px solid #72336a;
    border-radius: 10px;
    outline: none;
    display: flex;
  }

}

/* Desktop styles */
@media(min-width: 769px){
  
  .dropdown-select {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2vw;
    font-weight: 400;
    width: 40vw;
    padding: 12px 20px;
    margin-top: 15px;
    box-sizing: border-box;
    border: 1px solid #72336a;
    border-radius: 10px;
    outline: none;
    display: flex;
    /* modificaciones para flecha */
    appearance: none; /* Oculta la flecha nativa del select */
    background-image: url('https://cdn.jsdelivr.net/gh/FortAwesome/Font-Awesome@5.15.4/svgs/solid/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center; /* Posición de la flecha */
    background-size: 12px;
    padding-right: 30px; /* Epaciado para que la flecha no se solape con el texto */
  }

}