@import '~typeface-ubuntu/index.css';

/* Desktop styles
@media(min-width: 769px) { */
  .contenedor-principal-cot{
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    border-radius: 100px;
    background-color: white;
    margin-top: 3vw;
    margin-bottom: 200px;
    padding-bottom: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Efecto de sombra */
  }

  .titulo-info-cotizacion{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: 20px;
    flex-wrap: nowrap;
    font-size: 2.5vw;
    font-weight: bold;
    text-decoration: underline 0.5vw red;
  }

  .cuota-mensual{
    font-weight: bold;
    font-size: 2vw;
  }

  .precio-cuota-mensual{
    font-size: 1.5vw;
  }

  .contenedor-tipo-cobertura{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .titulo-tipo-cobertura{
    font-size: 1.5vw;
    font-weight: bold;
  }

  .valor-tipo-cobertura{
    font-size: 1.5vw;
  }

  .linea-roja-before-coberturas{
    border-bottom: solid 1px red;
  }

  .btn-seleccionar{
    font-family: 'Ubuntu', sans-serif;
    font-weight: 600;
    font-size: 1.7vw;
    border: none;
    color: white;
    background: linear-gradient(to left, #E51C30, #422A70); 
    cursor: pointer;
    align-items: center;
    margin-top: 30px;
    border-radius: 10px;
    width: 16vw;
    height: 5vw;
    letter-spacing: 1px;
  }

  .btn-back{
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 1.2vw;
    border: none;
    color: white;
    background: linear-gradient(to right, #323366, #069999); 
    cursor: pointer;
    align-items: center;
    margin-top: 30px;
    border-radius: 10px;
    width: 12vw;
    height: 2vw;
    letter-spacing: 1px;
  }

  .btn-seleccionar:hover, .btn-back:hover{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .contenedor-logo-seguro{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 8vw;
    margin-bottom: 50px;
  }

  .logo-seguro{
    width: auto;
    height: 10vh;
  }

  .titulo-cobertura{
    font-family: 'Ubuntu', sans-serif;
    display: inline-block;
    justify-content: center;
    align-content: center;
    font-weight: bold;
    font-size: 1.7vw;
    border-top: solid 6px red;
    margin-top: 50px;
    padding-top: 50px;
  }

  
/* } */

/* Mobile styles */
@media(max-width: 768px) {
  .titulo-info-cotizacion{
    font-size: 4.8vw;
  }

  .cuota-mensual{
    font-weight: bold;
    font-size: 3.5vw;
  }

  .precio-cuota-mensual{
    font-size: 3vw;
  }

  .titulo-tipo-cobertura{
    font-size: 3vw;
    font-weight: bold;
    margin-top: 10px;
    padding-top: 10px;
  }

  .valor-tipo-cobertura{
    font-size: 3vw;
  }

  .btn-seleccionar{
    width: 32vw;
    height: 12vw;
    font-size: 3.8vw;
  }

  .btn-back{
    width: 26vw;
    height: 8vw;
    font-size: 3.2vw;
  }

  .contenedor-logo-seguro{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 8vw;
    margin-bottom: 50px;
  }

  .logo-seguro{
    width: auto;
    height: 10vh;
  }

  .titulo-cobertura{
    font-family: 'Ubuntu', sans-serif;
    display: inline-block;
    justify-content: center;
    align-content: center;
    font-weight: bold;
    font-size: 1.7vw;
    border-top: solid 6px red;
    margin-top: 50px;
    padding-top: 50px;
  }
}