@media(min-width: 769px){
  .animated-gif{
    object-fit: cover;
    width: 26vw;
    height: 42vh;  
  }

  .animated-gif-AP{
    object-fit: cover;
    width: 23vw;
    height: 77vh;  
  }
}  
 
/* ------------------------------------------------ */

/* Mobile styles */
@media(max-width: 768px){  
  .animated-gif{
    object-fit: cover;
    width: 24vw;
    height: 24vw;
    margin-top: -5;
  }

  .animated-gif-AP{
    object-fit: cover;
    width: 14vh;
    height: 40vh;  
  }
}